<template>
  <div class="login">
    <div class="d-flex flex-column-fluid flex-center">
      <!--begin::Signin-->
      <div class="login-page mx-auto">
        <div class="login-box">
          <div class="login-logo">
            <router-link :to="{ name: 'Store home' }"><b>TUMAR</b></router-link>
          </div>
          <div class="card border-0">
            <div class="card-body login-card-body">
              <form
                v-if="!message && !loader"
                class="form"
                novalidate="novalidate"
                id="kt_login_signin_form"
                @submit.stop.prevent="onSubmit"
              >
                <p class="login-box-msg"
                  >You forgot your password? Here you can easily retrieve a new
                  password.</p
                >
                <div class="form-group">
                  <div class="input-group mb-3">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Email"
                      v-model="form.email"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div class="row no-gutters">
                    <div class="col col-12">
                      <button
                        type="submit"
                        class="btn btn-primary font-weight-bolder"
                        style="width: 100%; font-size: 16px"
                      >
                        Request new password
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div v-if="loader" class="d-flex justify-center">
                <v-progress-circular
                  color="grey"
                  width="3"
                  indeterminate
                ></v-progress-circular>
              </div>
              <p v-if="!loader && message" class="login-box-msg">{{
                message
              }}</p>
              <p class="mb-1">
                <v-list-item
                  :ripple="false"
                  link
                  :to="{ name: 'login' }"
                  class="px-0 font-weight-bolder"
                >
                  Login
                </v-list-item>
              </p>
              <p class="mb-1">
                <v-list-item
                  :ripple="false"
                  link
                  :to="{ name: 'register' }"
                  class="px-0 font-weight-bolder"
                >
                  Register new membership
                </v-list-item>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-list-item {
  min-height: unset;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #007bff;
  &:before {
    display: none;
  }
  &:hover {
    color: #0056b3;
  }
}
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
.form {
  button {
    color: white;
  }
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('auth')

export default {
  name: 'ForgotPassword',
  data() {
    return {
      state: 'signin',
      form: {
        email: '',
      },
      message: '',
      loader: false,
      baseUrl: this.$store.state.auth.API_URL,
    }
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(['currentUser']),

    backgroundImage() {
      return this.baseUrl + 'media/svg/illustrations/login-visual-1.svg'
    },
  },

  methods: {
    ...mapActions(['resetPassword']),
    onSubmit() {
      this.loader = true
      this.resetPassword(this.form.email).then((res) => {
        this.message = res.data.message
        this.loader = false
      })
    },
  },
}
</script>
